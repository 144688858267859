<template>
    <div >
      <el-button @click="createFormVisible = true" type="primary">创建收款账户</el-button>
  
      <el-dialog title="请输入收款账户信息" :visible.sync="createFormVisible" width="30%">
        <el-form :model="createForm" ref="createForm">
          <el-form-item label="银行">
            <el-input v-model="createForm.bank"></el-input>
          </el-form-item>
          <el-form-item label="账户">
            <el-input v-model="createForm.account"></el-input>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input v-model="createForm.name"></el-input>
          </el-form-item>
  
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="createFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="doCreate()
          createFormVisible = false;
          ">提交
          </el-button>
        </div>
      </el-dialog>
  
      <el-divider></el-divider>
  
      <el-table :data="dataList" border>
        <el-table-column prop="id" label="ID" width="80">
        </el-table-column>
        <el-table-column label="是否可用" width="80">
          <template #default="{row}">
            <el-switch v-model="row.enabled" active-color="#13ce66" inactive-color="#ff4949" @change="edit(row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="bank" label="银行"  >
        </el-table-column>
        <el-table-column prop="account" label="账户"  >
        </el-table-column>
        <el-table-column prop="name" label="姓名"  >
        </el-table-column>
        
      </el-table>
    </div>
  </template>
  <script>
  import { list, create, edit } from './ra'
  import msg from '../../util/msg'
  
  export default {
    data() {
      return {
  
        form: {
          id: null,
          userName: null,
        },
  
        createFormVisible: false,
        createForm: {
          bank: null,
          account: null,
          name: null,
        },
  
        dataList: null,

      };
    },
  
    methods: {

      edit,
  
      loadList() {
        list(this.form).then((data) => {
          this.dataList = data.list;
        });
      },
  
      doCreate() {
        create(this.createForm).then((data) => {
          msg.success();
          this.loadList();
        });
      }
  
    },
  
    created() {
      this.loadList();
    },
  
  };
  </script>