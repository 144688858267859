import axios from "axios";
import msg from "../../util/msg.js";

export function list(param) {
  return axios.get("/ra/list", { params: param });
}

export function create(param) {
  return axios.post("/ra/create", param);
}

export function edit(param) {
  axios.post("/ra/edit", param).then((resp) => {
    msg.success();
  });
}
